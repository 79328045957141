@font-face {
  font-family: "Proxima Nova";
  src: url("fonts/ProximaNova-Regular.woff2"); }

@font-face {
  font-family: "Proxima Nova";
  src: url("fonts/ProximaNova-SemiBold.woff2");
  font-weight: bold; }

@font-face {
  font-family: "Bungee";
  src: url("fonts/Bungee-Regular.woff2"); }

.switchWrapper {
  position: relative; }

body {
  background: #E9328B;
  font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-rendering: optimizeLegibility;
  margin: 0;
  padding: 0; }

section {
  overflow: hidden;
  text-align: left; }

.button {
  border: 2px solid #00D8FF;
  border-radius: 8px;
  font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px;
  color: #00D8FF;
  padding: 10px 10px;
  text-decoration: none; }

.error .errorHero {
  background: url("images/404-image.png") no-repeat;
  background-size: cover;
  height: 200px; }
  @media (min-width: 600px) {
    .error .errorHero {
      height: 430px; } }
  @media (min-width: 900px) {
    .error .errorHero {
      background-position: 50% -162px;
      height: 550px; } }

.error .errorContent {
  color: #FFFFFF;
  padding-top: 2rem;
  padding-bottom: 2rem; }
  .error .errorContent a {
    color: #A3C8EF;
    font-weight: bold;
    text-decoration: none; }
  .error .errorContent p {
    margin-left: 1rem; }
    @media (min-width: 600px) {
      .error .errorContent p {
        margin-left: 3rem; } }
    @media (min-width: 900px) {
      .error .errorContent p {
        margin-left: 5rem; } }

.home .homeHero {
  background: url("images/hero-home.jpg") no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  padding-top: 5.5rem;
  position: relative; }
  @media (min-width: 600px) {
    .home .homeHero {
      padding-top: 8rem;
      min-height: 570px;
      padding-top: 12rem;
      background-position: 50% 0%; } }
  @media (max-width: 599px) {
    .home .homeHero {
      padding-bottom: 4rem; } }

@media (min-width: 600px) {
  .home .homeDots {
    background: url("images/squiggly-polkadots.svg") no-repeat;
    background-size: contain;
    width: 411px;
    height: 414px;
    position: absolute; } }

@media (min-width: 600px) {
  .home .homeDots.homeDotsBottomRight {
    transform: rotate(180deg);
    right: 0;
    bottom: 0;
    filter: opacity(20%) grayscale(40%) contrast(175%); } }

@media (min-width: 600px) {
  .home .homeDots.homeDotsTopRight {
    transform: rotate(450deg);
    right: 0;
    top: -200px;
    filter: opacity(20%) grayscale(40%) contrast(175%); } }

@media (min-width: 600px) {
  .home .homeDots.homeDotsBottomLeft {
    transform: rotate(270deg);
    left: -235px;
    bottom: 0;
    filter: opacity(20%) grayscale(40%) contrast(175%); } }

.home .buttonHome {
  position: absolute;
  z-index:10;
  margin-bottom: 20px;
  text-align: left;
  max-width: 300px;
  min-width: 300px;
  margin-left: 1rem;
  background: url("images/icon-about.svg") 94% 50% no-repeat;
  padding-right: 50px; }
  @media (min-width: 600px) {
    .home .buttonHome {
      margin-top: 20px;
      margin-left: 0; } }

.home .headlineFake {
  color: #FFFFFF;
  text-shadow: 0.4rem 0 #23278A;
  font-size: 4.125rem;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Bungee", sans-serif;
  line-height: 0.85;
  position: relative; }
  @media (max-width: 599px) {
    .home .headlineFake {
      text-align: center;
      display: block;
      width: 300px;
      margin-left: auto;
      margin-right: auto; } }
  @media (min-width: 600px) {
    .home .headlineFake {
      font-size: 6.125rem; } }
  .home .headlineFake .headlineFakeManipulate {
    font-size: 8.5rem;
    display: block; }
    @media (min-width: 600px) {
      .home .headlineFake .headlineFakeManipulate {
        font-size: 12.5rem; } }
  .home .headlineFake:before {
    content: '';
    display: block;
    background: url("images/squiggly-sharp.svg") no-repeat;
    position: absolute;
    width: 300px;
    height: 183px;
    top: -30px;
    left: -10px; }
    @media (max-width: 599px) {
      .home .headlineFake:before {
        width: 100px;
        background-size: contain;
        top: 0;
        left: -10px; } }
  .home .headlineFake:after {
    content: '';
    display: block;
    background: url("images/squiggly-sharp.svg") no-repeat;
    position: absolute;
    width: 300px;
    height: 183px;
    bottom: -150px;
    right: 25px; }
    @media (max-width: 599px) {
      .home .headlineFake:after {
        width: 100px;
        background-size: contain;
        bottom: -170px;
        right: 0; } }

.home p {
  font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px;
  color: #FFFFFF;
  padding-bottom: 3rem;
  padding-top: 3rem;
  line-height: 1.4;
  margin-bottom: 0; }
  @media (max-width: 599px) {
    .home p {
      padding: 1.5rem 1rem 2rem 1rem; } }

@media (min-width: 600px) {
  .home .homeHeroContent {
    margin-left: 3rem;
    width: 30rem; } }

@media (min-width: 900px) {
  .home .homeHeroContent {
    margin-left: 5rem; } }

.home .homeArticles {
  background: #23278A;
  border-top: 10px solid #23278A;
  border-bottom: 10px solid #23278A; }
  .home .homeArticles h1 {
    font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    font-size: 45px;
    color: #FBC8F1;
    text-shadow: 5px 6px 5px rgba(17, 20, 75, 0.7);
    letter-spacing: 2px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem; }
    @media (min-width: 600px) {
      .home .homeArticles h1 {
        margin-left: 3rem;
        padding-top: 1.5rem; } }
    @media (min-width: 900px) {
      .home .homeArticles h1 {
        margin-left: 5rem; } }
  .home .homeArticles img {
    height: auto;
    max-width: 100%;
    vertical-align: middle; }
  .home .homeArticles .cardButton {
    border: 1px solid #FBC8F1;
    border-radius: 8px;
    color: #FBC8F1;
    padding: 10px;
    background: transparent;
    text-align: left;
    background: url("images/icon-arrow.svg") 96% 50% no-repeat;
    display: block;
    width: 95%;
    text-decoration: none;
    cursor: pointer; }
  .home .homeArticles .cards {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0; }
  .home .homeArticles .cardsItem {
    display: flex;
    padding: 10px 1rem 2.5rem 1rem; }
    @media (max-width: 599px) {
      .home .homeArticles .cardsItem {
        width: 100%;
        padding-bottom: 1rem; } }
    @media (min-width: 600px) {
      .home .homeArticles .cardsItem {
        width: 28.9%; } }
    @media (min-width: 900px) {
      .home .homeArticles .cardsItem {
        width: 29.9%; } }
    @media (min-width: 1200px) {
      .home .homeArticles .cardsItem {
        width: 30.5%; } }
  .home .homeArticles .card {
    background-color: #463185;
    border-radius: 10px;
    box-shadow: 0 20px 50px -14px rgba(63, 12, 54, 0.5);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    color: #FFFFFF; }
  .home .homeArticles .card:hover .cardImage {
    filter: contrast(100%);
    mix-blend-mode: unset; }
  .home .homeArticles .cardContent {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 1rem; }
  .home .homeArticles .cardImage {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    height: 250px;
    overflow: hidden;
    position: relative;
    transition: all ease-in-out .5s;
    display: block;
    text-indent: -9999px;
    background-color: #23278A;
    mix-blend-mode: lighten;
    -webkit-filter: grayscale(100%) contrast(1.2);
    filter: grayscale(100%) contrast(1);
    cursor: pointer; }
  .home .homeArticles .cardImage::before {
    content: "";
    display: block;
    padding-top: 56.25%; }
    @media (min-width: 600px) {
      .home .homeArticles .cardImage::before {
        padding-top: 66.6%; } }
  .home .homeArticles .capitalone {
    background-image: url("images/portfolio-thumb-capitalone.jpg"); }
  .home .homeArticles .icrossing {
    background-image: url("images/portfolio-thumb-icrossing.jpg"); }
  .home .homeArticles .hawkeye {
    background-image: url("images/portfolio-thumb-hawkeye.jpg"); }
  .home .homeArticles .cardTitle {
    color: #FBC8F1;
    font-size: 1.25rem;
    letter-spacing: 2px;
    text-transform: uppercase; }
    .home .homeArticles .cardTitle a {
      color: #FBC8F1;
      text-decoration: none;
      line-height: 1.3; }
  .home .homeArticles h2 {
    font-size: inherit;
    margin-bottom: 0;
    padding-bottom: 0; }
  .home .homeArticles .cardText {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    font-size: 0.85rem;
    line-height: 1.5;
    padding-top: 0;
    padding-bottom: 3rem; }

.project .projectContainer {
  margin-top: 3rem;
  background: #23278A;
  margin-bottom: 0;
  border-top: 10px solid #23278A;
  border-bottom: 10px solid #23278A; }
  @media (min-width: 600px) {
    .project .projectContainer {
      margin-top: 5rem; } }

.project .flexboxContainer {
  padding-bottom: 4rem; }
  @media (min-width: 600px) {
    .project .flexboxContainer {
      width: 90%; } }
  @media (min-width: 900px) {
    .project .flexboxContainer {
      width: 70%; } }

.project .projectContent p {
  color: #FFFFFF;
  margin-left: 1rem;
  line-height: 1.4; }
  @media (min-width: 600px) {
    .project .projectContent p {
      margin-left: 3rem; } }
  @media (min-width: 900px) {
    .project .projectContent p {
      margin-left: 5rem; } }

.project .projectContent li {
  color: #FFFFFF;
  margin-left: 6rem;
  line-height: 2;
}

.project .projectContent h1 {
  font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-size: 45px;
  color: #FBC8F1;
  text-shadow: 5px 6px 5px rgba(17, 20, 75, 0.7);
  letter-spacing: 2px;
  margin-top: 2rem;
  margin-bottom: 0px;
  margin-left: 1rem;
  line-height: 1.1; }
  @media (min-width: 600px) {
    .project .projectContent h1 {
      margin-left: 3rem; } }
  @media (min-width: 900px) {
    .project .projectContent h1 {
      margin-left: 5rem; } }

.project .projectContent h2 {
  color: #FBC8F1;
  margin-top: 4rem;
  margin-bottom: 2rem;
  margin-left: 1rem;
  line-height: 1.3; }
  @media (min-width: 600px) {
    .project .projectContent h2 {
      margin-left: 3rem; } }
  @media (min-width: 900px) {
    .project .projectContent h2 {
      margin-left: 5rem; } }

.project .projectContent img {
  max-width: 900px;
  display: block;
  margin-top: 2rem;
  box-shadow: 0 20px 50px -14px rgba(63, 12, 54, 0.8);
  border-top: 2px solid #E9328B;
  border-right: 2px solid #E9328B;
  border-bottom: 2px solid #E9328B; }
  @media (max-width: 599px) {
    .project .projectContent img {
      width: 100%; } }
  @media (min-width: 600px) {
    .project .projectContent img {
      max-width: 600px; } }
  @media (min-width: 900px) {
    .project .projectContent img {
      max-width: 800px; } }
  @media (min-width: 1200px) {
    .project .projectContent img {
      max-width: 1000px; } }

.about .wrapper {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 11fr 1fr;
  grid-gap: 10px; }

.about .wrapper > * {
  padding: 20px; }

.about .aboutHero {
  background: url("images/hero-about.jpg") no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  padding-top: 7rem;
  position: relative; }
  @media (min-width: 600px) {
    .about .aboutHero {
      padding-top: 40rem; } }
  .about .aboutHero .aboutCircle {
    background: url("images/squiggly-circledots.svg") no-repeat;
    background-size: contain;
    position: absolute;
    transform: rotate(90deg);
    filter: opacity(0.5); }
    @media (max-width: 599px) {
      .about .aboutHero .aboutCircle {
        display: none; } }
    @media (min-width: 600px) {
      .about .aboutHero .aboutCircle {
        top: 30px;
        left: 30px;
        width: 289px;
        height: 286px; } }
    @media (min-width: 1200px) {
      .about .aboutHero .aboutCircle {
        width: 389px;
        height: 386px; } }
  .about .aboutHero .aboutHalfCircle {
    background: url("images/squiggly-halfcircle.svg") no-repeat;
    background-size: contain;
    position: absolute;
    transform: rotate(135deg);
    filter: opacity(0.5); }
    @media (max-width: 599px) {
      .about .aboutHero .aboutHalfCircle {
        display: none; } }
    @media (min-width: 600px) {
      .about .aboutHero .aboutHalfCircle {
        top: 640px;
        right: -30px;
        width: 309px;
        height: 306px; } }
    @media (min-width: 1200px) {
      .about .aboutHero .aboutHalfCircle {
        width: 409px;
        height: 406px; } }
  .about .aboutHero .aboutCurvy {
    background: url("images/squiggly-curvylinesvertical.svg") no-repeat;
    background-size: contain;
    position: absolute;
    filter: opacity(0.5); }
    @media (max-width: 599px) {
      .about .aboutHero .aboutCurvy {
        display: none; } }
    @media (min-width: 600px) {
      .about .aboutHero .aboutCurvy {
        top: 0;
        right: 10px;
        width: 263px;
        height: 490px; } }
    @media (min-width: 1200px) {
      .about .aboutHero .aboutCurvy {
        width: 363px;
        height: 590px; } }
  .about .aboutHero .aboutCurvyUneven {
    background: url("images/squiggly-curvylines.svg") no-repeat;
    background-size: contain;
    position: absolute;
    filter: opacity(0.5); }
    @media (max-width: 599px) {
      .about .aboutHero .aboutCurvyUneven {
        display: none; } }
    @media (min-width: 600px) {
      .about .aboutHero .aboutCurvyUneven {
        top: 800px;
        left: 0;
        width: 616px;
        height: 202px; } }
    @media (min-width: 1200px) {
      .about .aboutHero .aboutCurvyUneven {
        width: 716px;
        height: 302px; } }
  .about .aboutHero blockquote {
    border: 0;
    padding: 0;
    color: #cccccc;
    font-size: 2rem;
    font-style: italic;
    line-height: 1.4;
    margin: 0;
    position: relative;
    z-index: 600; }
    .about .aboutHero blockquote * {
      box-sizing: border-box; }
    .about .aboutHero blockquote p {
      margin-top: 3.7rem;
      margin-bottom: 3.7rem; }
      .about .aboutHero blockquote p:first-child:before {
        content: '\201C';
        font-family: Georgia, serif;
        color: #81bedb;
        font-size: 10rem;
        font-weight: 700;
        opacity: .3;
        position: absolute;
        top: -.3em;
        left: 0;
        text-shadow: none;
        z-index: -300; }
  .about .aboutHero .headlineFake {
    text-align: center;
    color: #FFFFFF;
    text-shadow: 0.4rem 0 #23278A;
    font-size: 60px;
    text-transform: uppercase;
    margin-top: 0;
    font-family: "Bungee", sans-serif;
    line-height: 0.85;
    margin-bottom: 0;
    position: relative; }
    .about .aboutHero .headlineFake .headlineFakeManipulate0 {
      font-size: 20px;
      display: block; }
    .about .aboutHero .headlineFake .headlineFakeManipulate1 {
      font-size: 30px;
      display: block; }
    .about .aboutHero .headlineFake .headlineFakeManipulate2 {
      font-size: 50px;
      display: block; }
    @media (min-width: 600px) {
      .about .aboutHero .headlineFake {
        font-size: 150px; }
        .about .aboutHero .headlineFake .headlineFakeManipulate0 {
          font-size: 90px;
          display: block; }
        .about .aboutHero .headlineFake .headlineFakeManipulate1 {
          font-size: 110px;
          display: block; }
        .about .aboutHero .headlineFake .headlineFakeManipulate2 {
          font-size: 130px;
          display: block; } }
  @media (min-width: 600px) and (min-width: 900px) {
    .about .aboutHero .headlineFake {
      font-size: 210px; }
      .about .aboutHero .headlineFake .headlineFakeManipulate0 {
        font-size: 120px;
        display: block; }
      .about .aboutHero .headlineFake .headlineFakeManipulate1 {
        font-size: 160px;
        display: block; }
      .about .aboutHero .headlineFake .headlineFakeManipulate2 {
        font-size: 200px;
        display: block; } }
    @media (min-width: 1200px) {
      .about .aboutHero .headlineFake {
        font-size: 240px; } }
  .about .aboutHero .aboutHeroContent {
    background: rgba(35, 39, 138, 0.8);
    color: #FFFFFF;
    font-size: 18px;
    line-height: 1.4;
    margin-top: 3rem; }
    @media (min-width: 600px) {
      .about .aboutHero .aboutHeroContent {
        margin-top: 5rem; } }
    .about .aboutHero .aboutHeroContent .flexboxContainer {
      display: flex;
      width: 100%;
      padding-bottom: 4rem; }
      @media (max-width: 599px) {
        .about .aboutHero .aboutHeroContent .flexboxContainer {
          flex-direction: column; } }
    .about .aboutHero .aboutHeroContent .main a {
      color: #A3C8EF;
      text-decoration: none;
      font-weight: bold; }
    .about .aboutHero .aboutHeroContent .main .flexTableContainer {
      padding: 1em;
      margin-top: 3.7rem;
      margin-bottom: 3.7rem; }
      @media (min-width: 900px) {
        .about .aboutHero .aboutHeroContent .main .flexTableContainer {
          display: flex; } }
    .about .aboutHero .aboutHeroContent .main .flexItem {
      flex: 1;
      width: 0;
      width: 100%; }
      @media (min-width: 900px) {
        .about .aboutHero .aboutHeroContent .main .flexItem {
          width: auto; } }
    .about .aboutHero .aboutHeroContent .main .flexItem:not(:last-child) {
      margin-right: 1em; }
    .about .aboutHero .aboutHeroContent .main .package {
      border: 1px solid #34389C;
      list-style-type: none;
      margin: 0;
      padding: 0;
      transition: 0.25s;
      box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2); }
    .about .aboutHero .aboutHeroContent .main .package .header {
      background-color: #15175B;
      color: #FBC8F1;
      font-size: 1.4rem;
      font-weight: bold;
      text-transform: uppercase; }
    .about .aboutHero .aboutHeroContent .main .package li {
      border-bottom: 1px solid #34389C;
      padding: 1rem;
      text-align: center; }
    .about .aboutHero .aboutHeroContent .sidebar ul {
      margin-top: 1rem; }
      @media (min-width: 600px) {
        .about .aboutHero .aboutHeroContent .sidebar ul {
          padding-left: 0; } }
    .about .aboutHero .aboutHeroContent .sidebar li {
      list-style: none;
      display: block; }
    .about .aboutHero .aboutHeroContent h1 {
      font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
      text-transform: uppercase;
      font-size: 45px;
      color: #FBC8F1;
      text-shadow: 5px 6px 5px rgba(17, 20, 75, 0.7);
      letter-spacing: 2px;
      margin-top: 0;
      margin-bottom: 0px;
      margin-left: 1rem;
      text-align: left;
      padding-top: 1rem; }
      @media (min-width: 600px) {
        .about .aboutHero .aboutHeroContent h1 {
          padding-top: 2rem;
          margin-left: 5rem; } }
    .about .aboutHero .aboutHeroContent p {
      margin-left: 1rem;
      margin-right: 1rem; }
      @media (min-width: 600px) {
        .about .aboutHero .aboutHeroContent p {
          margin-left: 5rem;
          margin-right: 3rem; } }
    .about .aboutHero .aboutHeroContent ul {
      margin-bottom: 0;
      margin-left: 0; }
      @media (max-width: 599px) {
        .about .aboutHero .aboutHeroContent ul {
          padding-left: 0; } }
      @media (min-width: 600px) {
        .about .aboutHero .aboutHeroContent ul {
          margin-left: 5rem; } }
    .about .aboutHero .aboutHeroContent .aboutLink {
      border: 2px solid #00D8FF;
      border-radius: 8px;
      font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 18px;
      color: #00D8FF;
      padding: 10px 10px;
      text-decoration: none;
      margin-bottom: 20px;
      text-align: left;
      margin-left: 1rem;
      padding-right: 50px;
      display: block;
      margin-right: 3rem; }
      .about .aboutHero .aboutHeroContent .aboutLink.aboutLinkResume {
        background: url("images/icon-resume.svg") 94% 50% no-repeat; }
      .about .aboutHero .aboutHeroContent .aboutLink.aboutLinkLinkedin {
        background: url("images/icon-linkedin.svg") 94% 50% no-repeat; }
      .about .aboutHero .aboutHeroContent .aboutLink.aboutLinkInstagram {
        background: url("images/icon-instagram.svg") 94% 50% no-repeat; }
      @media (min-width: 600px) {
        .about .aboutHero .aboutHeroContent .aboutLink {
          margin-top: 20px;
          margin-left: 0; } }
      @media (min-width: 900px) {
        .about .aboutHero .aboutHeroContent .aboutLink {
          max-width: 200px;
          min-width: 200px; } }
  .about .aboutHero .profilePic {
    max-width: 260px;
    clip-path: circle(125px at 50% 50%); }
    @media (max-width: 599px) {
      .about .aboutHero .profilePic {
        margin-top: 2rem;
        margin-left: 1rem; } }
    @media (min-width: 600px) {
      .about .aboutHero .profilePic {
        margin-top: 3rem;
        margin-left: 4rem; } }

.contact .contactHero {
  background: url("images/hero-contact.jpg") no-repeat;
  background-size: cover; }
  .contact .contactHero::before {
    background: url("images/squiggly-seamlesspattern.svg");
    background-size: contain;
    filter: opacity(25%) grayscale(50%) contrast(175%) drop-shadow(5px 5px 5px #333);
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 100%; }
  .contact .contactHero .flexboxContainer {
    display: flex;
    width: 100%;
    padding-bottom: 0; }
    @media (max-width: 599px) {
      .contact .contactHero .flexboxContainer {
        padding-top: 6rem;
        flex-direction: column; } }
    @media (min-width: 600px) {
      .contact .contactHero .flexboxContainer {
        padding-top: 9rem;
        padding-bottom: 4rem; } }
  .contact .contactHero .sidebar {
    order: 1;
    flex: 1;
    padding: .5rem; }
    .contact .contactHero .sidebar .headlineFake {
      text-align: center;
      color: #FFFFFF;
      text-shadow: 0.4rem 0 #23278A;
      font-size: 15px;
      text-transform: uppercase;
      margin-top: 0;
      font-family: "Bungee", sans-serif;
      line-height: 0.85;
      margin: 0 auto;
      position: relative;
      display: block;
      padding-top: 0;
      padding-bottom: 3rem; }
      .contact .contactHero .sidebar .headlineFake span {
        display: block;
        font-size: 70px; }
        @media (min-width: 600px) {
          .contact .contactHero .sidebar .headlineFake span {
            font-size: 75px; } }
        @media (min-width: 900px) {
          .contact .contactHero .sidebar .headlineFake span {
            font-size: 110px; } }
        @media (min-width: 1200px) {
          .contact .contactHero .sidebar .headlineFake span {
            font-size: 120px; } }
      @media (min-width: 600px) {
        .contact .contactHero .sidebar .headlineFake {
          font-size: 33px;
          padding-top: 5rem;
          padding-bottom: 0;
          width: 90%; } }
      @media (min-width: 900px) {
        .contact .contactHero .sidebar .headlineFake {
          font-size: 30px; } }
  .contact .contactHero .main {
    order: 2;
    flex: 1;
    background: rgba(35, 39, 138, 0.8);
    color: #FFFFFF;
    z-index: 1; }
    .contact .contactHero .main p {
      line-height: 1.5;
      margin-left: 1.75rem;
      margin-right: 3.75rem;
    }
    .contact .contactHero .main h1 {
      font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
      text-transform: uppercase;
      letter-spacing: 1;
      font-size: 45px;
      color: #FBC8F1;
      text-shadow: 5px 6px 5px rgba(17, 20, 75, 0.7);
      letter-spacing: 2px;
      margin-top: 1rem;
      margin-bottom: 0px;
      margin-left: 1rem;
      text-align: left;
      padding-top: 1rem; }
      @media (min-width: 600px) {
        .contact .contactHero .main h1 {
          margin-left: 1.5rem;
          padding-top: 1.5rem; } }
  .contact .contactHero a {
    color: #A3C8EF;
    text-decoration: none;
    font-weight: bold; }
  .contact .contactHero form {
    margin-top: 1.5rem;
    margin-left: 1rem;
    margin-bottom: 1.5rem;
    font-size: 18px;
    padding-bottom: 2rem; }
    @media (max-width: 599px) {
      .contact .contactHero form {
        margin-right: 1rem; } }
    @media (min-width: 900px) {
      .contact .contactHero form {
        width: 70%; } }
  .contact .contactHero input {
    display: block; }
  .contact .contactHero legend {
    margin-bottom: 20px;
    line-height: 1.4; }
    @media (min-width: 600px) {
      .contact .contactHero legend {
        margin-right: 0.5rem; } }
    @media (min-width: 900px) {
      .contact .contactHero legend {
        margin-right: 0; } }
  .contact .contactHero input[type=email] {
    background: #1C1F6B;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    color: #FFFFFF;
    padding: 10px 10px;
    font-size: 18px;
    border-bottom: 5px solid #FBC8F1;
    margin-bottom: 5px; }
    @media (max-width: 599px) {
      .contact .contactHero input[type=email] {
        width: 95%; } }
    @media (min-width: 600px) {
      .contact .contactHero input[type=email] {
        width: 90%; } }
    @media (min-width: 900px) {
      .contact .contactHero input[type=email] {
        width: 100%; } }
    .contact .contactHero input[type=email]:focus {
      border-bottom: 5px solid #A3C8EF; }
  .contact .contactHero input[type=submit] {
    background: transparent;
    border: 2px solid #31FF3D;
    border-radius: 8px;
    font-size: 18px;
    color: #31FF3D;
    min-width: 200px;
    text-align: left;
    padding: 10px;
    background: url("images/icon-email.svg") 92% 50% no-repeat; }
    .contact .contactHero input[type=submit]:disabled {
      border: 2px solid #1f9a26;
      color: #1f9a26;
      background: transparent; }
  .contact .contactHero textarea {
    resize: none;
    background: #1C1F6B;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    color: #FFFFFF;
    padding: 10px 10px;
    font-size: 18px;
    border-bottom: 5px solid #FBC8F1;
    margin-bottom: 0;
    height: 100px; }
    @media (max-width: 599px) {
      .contact .contactHero textarea {
        width: 95%; } }
    @media (min-width: 600px) {
      .contact .contactHero textarea {
        width: 90%; } }
    @media (min-width: 900px) {
      .contact .contactHero textarea {
        width: 100%; } }
    .contact .contactHero textarea:focus {
      border-bottom: 5px solid #A3C8EF; }
  .contact .contactHero .error {
    color: #ff4848; }
  .contact .contactHero label {
    display: block;
    margin-bottom: 30px;
    font-size: 14px;
    color: #FBC8F1; }

header {
  background: linear-gradient(217deg, rgba(128, 12, 106, 0.8), rgba(128, 12, 106, 0) 90.71%), linear-gradient(127deg, rgba(128, 12, 106, 0.6), rgba(128, 12, 106, 0) 30.71%), linear-gradient(336deg, rgba(200, 0, 255, 0.8), rgba(200, 0, 255, 0) 70.71%);
  display: flex;
  align-items: center;
  width: 100%;
  position: absolute;
  justify-content: space-between;
  top: 0;
  z-index: 1; }
  header h1 {
    display: block;
    order: 1;
    flex: 2 1;
    margin-left: 1rem;
    font-size: initial; }
    header h1 img {
      width: 80px;
      height: 100%; }
      @media (min-width: 600px) {
        header h1 img {
          width: 160px; } }
  header nav ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0; }
  header nav li {
    flex: 1; }
    header nav li a {
      font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: bold;
      font-size: 12px;
      color: #FFFFFF;
      text-decoration: none;
      text-transform: uppercase;
      position: relative;
      display: block;
      padding-top: 10px;
      padding-bottom: 10px;
      display: block;
      text-align: center; }
      @media (max-width: 599px) {
        header nav li a {
          margin-right: 2rem; } }
      @media (min-width: 600px) {
        header nav li a {
          font-size: 25px;
          margin-right: 2rem; } }
      @media (min-width: 900px) {
        header nav li a {
          margin-right: 0; } }
      header nav li a.is-active {
        color: #FBC8F1;
        background: url("images/bg-nav.svg") no-repeat;
        background-size: contain;
        background-position: 50% 50%; }
  header .navContainer {
    order: 2;
    flex: 1 1 0%; }

footer {
  background: url("images/bg-footer.png") repeat-x;
  padding-top: 110px;
  padding-bottom: 15px;
  font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #E9328B;
  text-align: center; }
  footer a {
    color: #E9328B; }
